import logo from "./logo.svg";
import "./App.css";
import FaqList from "./FaqList";
import { Box, Typography, Button } from "@mui/material";
import heroImage from "./assets/Our-Lady-of-the-Rocks.jpeg";

function App() {
  return (
    <div>
      <Box
        sx={{
          height: "60vh", // Adjust height as needed
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: `url(${heroImage})`, // Replace with your image URL
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "#fff", // Text color
        }}
      ></Box>

      {/* Main Content */}
      <Box
        sx={{
          p: { xs: 0, lg: 4 }, // Padding 4 only on large devices
          textAlign: "center",
        }}
      >
        <Typography variant="h3" fontFamily={"Waterfall, cursive"}>
          Klaudia & Jacek
        </Typography>
        <Typography variant="h4" fontFamily={"Waterfall, cursive"}>
          29.08.2025
        </Typography>
        <Typography variant="h4" fontFamily={"Waterfall, cursive"}>
          Czarnogóra
        </Typography>
      </Box>
      <Box>
        <FaqList />
      </Box>
    </div>
  );
}

export default App;
