import * as React from 'react';
import FaqItem from './FaqItem';
import { Container } from '@mui/material';

export default function FaqList() {
const faqData = [
    {
        question: "Transport do Czarnogóry",
        answer: "Czarnogóra posiada 2 lotniska: głowne w Podgoricy oraz mniejsze w miejscowości Tivat. Z Podgoricy do Zatoki Kotorskiej (Perast) jest około 2h, z Tivatu ok. 0,5h. Bezpośrednie loty do Podgoricy odbywają się z Krakowa, Katowic oraz Warszawy, natomiast, do Tivatu lotów bezpośrednich nie ma, można się tam dostać np. Austrian airlines z Krakowa z przesiadką w Wiedniu."
    },
    {
        question: "Nocleg",
        answer: "Niestety nie jesteśmy w stanie zapewnić dla wszystkich gości noclegu w jednym miejscu. Nad Zatoką Kotorską nie ma dużych hoteli, są raczej apartamenty, hotele butikowe z niewielką liczbą pokoi itp., co dla nas by oznaczało, że musielibyśmy kwaterować gości w róznych miejscach. Dlatego kwestię noclegów zostawiamy po Waszej stronie, tak abyście mogli sami wybrać obiekt, w którym chcecie przebywać i z kim ewentualnie chcecie go dzielić. Wszelkie koszty zakwaterowania poniesione po Waszej stronie zrekompensujcie sobie w kopertach :)"
    },
    {
        question: "Potwierdzenie przybycia",
        answer: "Bardzo prosimy o jak najszybsze potwierdzenie przybycia - najlepiej do końca marca 2025. Jeśli z różnych względów potrzebowalibyście więcej czasu do namysłu - dajcie nam proszę o tym znać. Komunikować się z nami najlepiej za pomocą mesangera lub whatsappa."
    },
    {
        question: "Transport w dniu ślubu",
        answer: "Jakoże ślub odbywa się na wyspie, zapewniamy transport wodny na nią z miejscowości Perast (godzina oraz miejsce zbiórki zostaną podane w późniejszym terminie). Z kościoła również obierze nas łódka, którą to dostaniemy się już bezpośrednio do restauracji Bocasa (istnieje możliwość powrotu z kościoła na miejsca zbiórki, jakby ktoś zaplanował jechać autem). Po zakończonej imprezie, będzie do dyspozycji kierowca z busikiem, który Was odwiezie do hoteli."
    },
    {
        question: "Ślub",
        answer: "Ślub zostanie odprawiony przez polskojęzycznego księdza. Widoki z wysepki na której znajduje się kościół są przepiękne, także serdecznie polecamy się tam wybrać nawet jeśli nie chcielibyście uczestniczyć w ceremonii zaślubin"
    },
    {
        question: "Wesele",
        answer: "Po przypłynięciu do restauracji będzie dostępny open bar - kelnerzy będą zbierać zamówienia przy stolikach. Jednak pierwszy posiłek zaplanowany jest na 19 (miejmy nadzieję, że już się trochę ochłodzi), więc proszę to wziąć pod uwagę przy planowaniu żywieniowym na ten dzień."
    },
    {
        question: "Jedzenie/napoje",
        answer: "Karty z menu oraz dostępne napoje alkoholowe oraz bezalkoholowe będą dostępne przy stolikach. Jeśli Wasza dieta jest wegetariaństa/wegańska/bezglutenowa itp. prosimy o taką informację przy potwierdzaniu przybycia."
    },
    {
        question: "Prezenty",
        answer: "Jeśli planujecie nam coś podarować, bardzo prosimy, aby nie było to zbyt duże z racji ograniczonego bagażu. W ramach podrózy poślubnej planujemy spędzić trochę czasu na Bałkanach, więc również prosimy, aby wszelkie podarowane nam pieniążki było denominowane w euro, lub przelane na poniższe konto bankowe (PLN)"
    },
    {
        question: "Ubiór&inne",
        answer: "Koniec sierpnia może być baaardzo gorący, więc rozważcie, czy chcecie brać pełny garniak. Wysepka na której jest ślub oraz wybrzeże nad którym znajduje się restauracja są kamieniste, więc też to weźcie pod uwagę jak będziecie patrzeć na piękne szpilki w szafie. Butelka wody na czas zanim dopłyniemy do restauracji napewno się przyda :)"
    }
];

return (
    <Container sx={{ paddingLeft: { xs: 0, lg: 4 }, paddingRight: { xs: 0, lg: 4 } }}>
        {faqData.map((faq, index) => (
            <FaqItem key={index} question={faq.question} answer={faq.answer} />
        ))}
    </Container>
);
}